import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AliceCarousel from 'react-alice-carousel';

import logoImg1 from './images/logo-1.png'
import logoImg2 from './images/logo-2.png'
import logoImg3 from './images/logo-3.png'
import logoImg4 from './images/logo-4.png'
import logoImg5 from './images/logo-5.png'
import css from './LogosCarousel.module.css';

const LogosCarousel = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const items = [
    <img src={logoImg1} alt="Au petit studio" />,
    <img src={logoImg2} alt="Aux angles ronds" />,
    <img src={logoImg3} alt="Caucus" />,
    <img src={logoImg4} alt="Kptur Studio" />,
    <img src={logoImg5} alt="OzStudio" />,
  ];

  return (

    <section className={classes} id="logosCarousel">

      <div className={css.carouselWrapper}>
        <AliceCarousel
          // mouseTracking
          items={items}
          disableDotsControls
          disableButtonsControls
          // autoWidth={true}
          infinite={true}
          autoPlay={true}
          autoPlayInterval={1500}
          responsive={{
            0: { items: 2 },
            768: { items: 3 },
            1280: { items: 5 },
          }}
        />
      </div>

    </section>

  );
};

LogosCarousel.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

LogosCarousel.propTypes = {
  rootClassName: string,
  className: string,
};

export default LogosCarousel;
