import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPerson.module.css';

const IconPerson = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <filter
          id="alpha"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          ></feColorMatrix>
        </filter>
        <mask id="mask0">
          <g filter="url(#alpha)">
            <path fillOpacity="0.922" d="M0 0H24V24H0z"></path>
          </g>
        </mask>
        <clipPath id="clip1">
          <path d="M0 0H24V24H0z"></path>
        </clipPath>
        <g id="surface5" clipPath="url(#clip1)">
          <path
            fillRule="evenodd"
            d="M11.79.586c1.534.027 2.448.793 2.741 2.297.047 1.543-.68 2.488-2.18 2.836-1.382.11-2.328-.477-2.835-1.758-.336-1.57.234-2.656 1.71-3.258.196-.031.383-.07.563-.117zm-.048.703c1.11-.004 1.805.527 2.086 1.594.059 1.097-.433 1.808-1.476 2.133-1.172.113-1.915-.395-2.227-1.524-.059-1.183.48-1.918 1.617-2.203zm0 0"
          ></path>
        </g>
        <mask id="mask1">
          <g filter="url(#alpha)">
            <path fillOpacity="0.933" d="M0 0H24V24H0z"></path>
          </g>
        </mask>
        <clipPath id="clip2">
          <path d="M0 0H24V24H0z"></path>
        </clipPath>
        <g id="surface8" clipPath="url(#clip2)">
          <path
            fillRule="evenodd"
            d="M9.164 6.492c1.953-.023 3.906 0 5.86.07.996.325 1.566 1.004 1.71 2.04.032 2.14.032 4.28 0 6.421-.277.918-.87 1.23-1.78.938l-.048 6c-.18.875-.719 1.344-1.617 1.406a1.857 1.857 0 01-1.312-.61c-.743.708-1.54.778-2.391.212a1.592 1.592 0 01-.492-.774A207.375 207.375 0 019 15.961c-.91.293-1.504-.02-1.781-.938a223.184 223.184 0 010-6.468c.191-1.121.84-1.809 1.945-2.063zm.047.703c1.844-.008 3.687 0 5.531.024.625.156 1.04.539 1.242 1.148.079 2.188.094 4.375.047 6.563-.129.386-.386.52-.773.398a.567.567 0 01-.305-.351l-.047-5.204c-.125-.23-.304-.285-.539-.164a.354.354 0 00-.117.164l-.047 12.141c-.21.602-.625.828-1.242.68a.913.913 0 01-.586-.586 278.004 278.004 0 01-.094-7.219c-.203-.25-.406-.25-.61 0 0 2.406-.03 4.813-.093 7.219-.32.62-.797.785-1.43.492a.964.964 0 01-.398-.586l-.047-12.14c-.129-.215-.308-.27-.539-.165a.354.354 0 00-.117.164L9 14.977c-.188.402-.477.503-.867.304a.694.694 0 01-.211-.304c-.031-2.125-.031-4.25 0-6.375.148-.735.578-1.204 1.289-1.407zm0 0"
          ></path>
        </g>
      </defs>
      <g>
        <use mask="url(#mask0)" xlinkHref="#surface5"></use>
        <use mask="url(#mask1)" xlinkHref="#surface8"></use>
      </g>
    </svg>
  );
};

IconPerson.defaultProps = { className: null };

IconPerson.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPerson;
