import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconUser.module.css';

const IconUser = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      overflow="hidden"
      viewBox="0 0 1024 1024"
    >
      <path d="M78.223 968.301c0 14.22 9.48 23.699 23.699 23.699s23.699-9.48 23.699-23.699c0-213.33 173.035-386.366 386.366-386.366s386.379 173.036 386.379 386.366c0 14.22 9.48 23.699 23.699 23.699s23.699-9.48 23.699-23.699c0-196.748-132.74-362.667-310.516-417.194C725.317 506.072 786.96 413.586 786.96 306.96 786.96 155.26 663.7 32 511.987 32S237.039 155.261 237.039 306.96c0 106.666 61.63 199.112 151.7 244.147C208.6 603.257 78.223 769.177 78.223 968.3zM284.45 304.544C284.45 178.92 386.376 76.981 512 76.981s227.563 101.992 227.563 227.563S637.651 532.147 512 532.147 284.45 430.222 284.45 304.544zm0 0"></path>
    </svg>
  );
};

IconUser.defaultProps = {
  rootClassName: null,
  className: null,
};

IconUser.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconUser;
