import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import { ValidationError, IconArrowHead } from '../../components';
import classNames from 'classnames';

import css from './FieldSelect2.module.css';

const Input = props => {
  return (
    <Field
      {...props}
      type="hidden"
      // value={value}
      component={renderProps => {
        const {
          customErrorText,
          meta,
          responseError
        } = renderProps;

        const { invalid, touched, error } = meta;
        const errorText = error || responseError;
        const hasError = (!!customErrorText || !!(touched && invalid && error)) || responseError;
        const fieldMeta = { touched: hasError, error: errorText };

        return (
          <>
            <ValidationError fieldMeta={fieldMeta} />
          </>
        )
      }}
    />
  )
}
class FieldSelect2 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedValue) {
    const { form, name, updateSelectValue } = this.props;
    form.change(name, selectedValue)
    updateSelectValue(selectedValue)
  }

  render() {
    const {
      id,
      intl,
      className,
      form,
      label,
      disabled,
      placeholder,
      requiredMessage,
      currentUser,
      options,
      selectedOptions,
      isSubCategory,
      isMulti,
      ...rest
    } = this.props;

    const classes = classNames(
      css.root,
      className,
    );

    const selectStyle = {
      control: (provided, state) => ({
        ...provided,
        border: 'none',
        borderRadius: '0px',
        boxShadow: "none",
        "&:hover": {
          border: 'none',
          boxShadow: "none",
        },
        "&:focus": {
          border: 'none',
          boxShadow: "none",
        }

      }),
      valueContainer: (provided, state) => ({
        ...provided,
        minHeight: '46px',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        fontSize: '16px',
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? '#fff' : isSelected ? "#ff4a76" : "#fff",
          color: isFocused ? '#4a4a4a' : isSelected ? "#fff" : "#4a4a4a",
          fontSize: '16px',
          
          "&:hover": {
            backgroundColor: '#ff4a76',
            color: '#fff',
          },
          "&:focus": {
            backgroundColor: '#ff4a76',
            color: '#fff',
          }
        };
      },


    }

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <IconArrowHead direction={props.selectProps.menuIsOpen ? "up" : "down"} size="big" />
          </components.DropdownIndicator>
        )
      );
    };

    return (
      <div className={classes}>

        {label && (
          <label>
            {label}
          </label>
        )}

        <Select
          // defaultMenuIsOpen={true}
          className={css.root}
          isMulti={isMulti}
          autoComplete
          options={options}
          required={true}
          placeholder={placeholder}
          label={requiredMessage}
          styles={selectStyle}
          onChange={this.handleChange}
          defaultValue={!!selectedOptions ? selectedOptions : null}
          components={{ DropdownIndicator }}
        />

        <Input
          disabled={disabled}
          {...rest}
        />
      </div>
    )
  }
}

export default compose(injectIntl)(FieldSelect2);
