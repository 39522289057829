import React, { useEffect, useState } from 'react';
import enFlag from './icons/enFlagIcon.png';
import frFlag from './icons/frFlagIcon.png';
import css from './LanguageSwitcher.module.css';

import { Menu, MenuLabel, MenuContent, MenuItem, IconGlobe } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { handleSwitchLanguage } from '../../ducks/ui.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const LanguageSwitcherComponent = props => {
  const { onHandleSwitchNewLanguage, currentUser, language, onUpdateProfile } = props;

  const [isOpen, setIsOpen] = useState(false)

  const onToggleActive = (isOpen) => {
    setIsOpen(isOpen)
  }

  //set language to saved value if any
  useEffect(() => {
    const savedLanguageMaybe =
      currentUser?.attributes?.profile?.protectedData?.language ||
      window.sessionStorage.getItem('sessionLanguage') ||
      language;
    if (language !== savedLanguageMaybe) {
      onHandleSwitchNewLanguage(savedLanguageMaybe);
    }
  }, []);

  const handleLanguageSwitch = async lang => {
    //change language state
    await onHandleSwitchNewLanguage(lang);
    //update currentUser
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    } else {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('sessionLanguage', lang);
      }
    }
    setIsOpen(false)
  };

  let flagLabel = frFlag;
  switch (language) {
    case 'en':
      flagLabel = enFlag;
      break;
    case 'fr':
      flagLabel = frFlag;
      break;
  }

  return (
    <div className={css.langSwitchWrapper}>
      <Menu isOpen={isOpen} onToggleActive={onToggleActive}>
        <MenuLabel className={css.langSwitchLabel} isOpenClassName={css.langSwitchIsOpen}>
          <div className={css.langSwitchIcon} onClick={() => setIsOpen(true)}>
            <IconGlobe />
          </div>
          {/* <img src={flagLabel} className={css.icon} /> */}
        </MenuLabel>
        <MenuContent className={css.langSwitchContent} useDefaultStyle={false}>
          <MenuItem key="en">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('en')}>
              {/* <img src={enFlag} className={css.icon} /> */}
              <span className={css.label}>
                <FormattedMessage id={'LanguageSwither.en'} />
              </span>
            </div>
          </MenuItem>
          <MenuItem key="fr">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('fr')}>
              {/* <img src={frFlag} className={css.icon} /> */}
              <span className={css.label}>
                <FormattedMessage id={'LanguageSwither.fr'} />
              </span>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.ui;

  return {
    currentUser,
    language,
  };
};

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LanguageSwitcher = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LanguageSwitcherComponent);

export default LanguageSwitcher;
