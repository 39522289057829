import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = props => {
  const { footer = {} } = useConfiguration();

  const { language } = props;

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: 'footer',
    sectionType: 'footer',
    language
  };

  return <SectionBuilder sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.ui;

  return {
    currentUser,
    language,
  };
};

const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
export default FooterContainer;

// export default FooterComponent;
