import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  Form,
  Button,
  IconSearch,
  IconUser,
  FieldTextInput,
  FieldSelect2
} from '../../components';
import defaultConfig from '../../config/configDefault';

import css from './AdvancedSearch.module.css';

export const AdvancedSearchForm = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        values,
        onSubmit,
        getValues,
        updateSelectValue,
        selectValue,
        config,
        form
      } = formRenderProps;

      const eventTypes = config?.listing?.listingFields?.find(item => item.key === "eventType")?.enumOptions
        || defaultConfig?.eventTypes;

      const eventTypesWithTranslations = eventTypes?.map(e => {
        return {
          value: e?.option || e?.key,
          label: intl.formatMessage({
            id: `CustomField.${e?.option || e?.key}`,
          })
        }
      })

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      // const submitDisabled = invalid || disabled || submitInProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={css.rootForm} onSubmit={(e) => { e.preventDefault(), onSubmit }}>

          <div className={css.formFieldHolder}>
            <span className={css.selectLabelText}>
              <IconSearch className={css.fieldIcon} />
            </span>
            <FieldSelect2
              form={form}
              id="eventType"
              name="eventType"
              className={css.select}
              placeholder={intl.formatMessage({
                id: 'AdvancedSearch.eventPlaceholder',
              })}
              options={eventTypesWithTranslations}
              selectedOptions={selectValue}
              updateSelectValue={updateSelectValue}
            />
          </div>

          <div className={css.formFieldHolder}>
            <IconUser className={css.fieldIcon} />
            <FieldTextInput
              type="number"
              id="searchCapacity"
              name="searchCapacity"
              className={classNames(css.formField, css.numberField)}
              placeholder={intl.formatMessage({
                id: 'AdvancedSearch.searchCapacityPlaceholder',
              })}
              step={1}
              min={0}
            />
          </div>
          <Button
            className={css.submitButton}
            type="button"
            inProgress={submitInProgress}
            ready={submitReady}
            disabled={submitDisabled}
            onClick={() => getValues(values)}
          >
            <IconSearch className={css.submitButtonIcon} />
            <FormattedMessage id="AdvancedSearch.search" />
          </Button>
        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
