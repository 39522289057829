import React, { useState } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';

import AdvancedSearchForm from './AdvancedSearchForm';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useConfiguration } from '../../context/configurationContext';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './AdvancedSearch.module.css';

const { LatLng, LatLngBounds } = sdkTypes;

const AdvancedSearch = props => {
  const { intl, topbarLayout, currentPage, setCategoryForLocation } = props;
  
  const history = useHistory();
  const location = useLocation();
  const config = useConfiguration();

  const routeConfiguration = useRouteConfiguration();

  const [selectFilterOpen, setSelectFilterOpen] = useState(false);

  const [selectValue, setSelectValue] = useState(null);

  const updateSelectValue = type => {
    setSelectValue(type)
    setSelectFilterOpen(false)

    !!setCategoryForLocation && setCategoryForLocation(type?.option)
  }

  const applySelectFilter = status => {
    setSelectFilterOpen(status)
  }
  
  const onSubmit = () => {
    return;
  };

  const selectedFilters = parse(location?.search) || {};
  const getValues = values => {

    const searchParams = {
      pub_eventType: selectValue?.value || selectValue?.key || null,
      // keywords: values?.eventType || null,
      bounds: new LatLngBounds(new LatLng(45.62723128,-73.42609894), new LatLng(45.36348926, -73.75224371)),
      pub_searchCapacity: values?.searchCapacity || null,
    };
    !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const initialSearchFormValues = {};

  return (
    <div className={css.root}>
      <AdvancedSearchForm
        topbarLayout={topbarLayout}
        onSubmit={onSubmit}
        getValues={getValues}
        intl={intl}

        updateSelectValue={updateSelectValue}
        applySelectFilter={applySelectFilter}

        selectFilterOpen={selectFilterOpen}
        selectValue={selectValue}
        
        initialValues={initialSearchFormValues}
                
        currentPage={currentPage}
        selectedFilters={selectedFilters}
        config={config}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
